import axios from 'axios';
export default class Searchbox {
  constructor(element) {
    this.el = element;
    this.suggestionsList = this.el.querySelector('.lstSuggestions');
    this.searchbox = this.el.querySelector('.tbSearchBox');
    this.typeAheadUrl = `${window.location.origin}/api/searchapi/typeahead`;
    this.init();
  }

  init() {
    if (this.getParamsObj()['term']) {
      if (this.el.getAttribute('data-search') !== 'page') {
        this.el.remove();
        return;
      }
      setTimeout(() => {
        this.searchbox.value = this.getParamsObj()['term'];
        this.search(this.searchbox.value);
        this.boxLogic(true);
      }, 5);
    }

    this.searchbox.addEventListener('input', () => {
      this.boxLogic();
    });

    this.searchbox.addEventListener('focus', () => {
      if (this.suggestionsList.childNodes.length) {
        this.suggestionsList.classList.add('visible');
        document.addEventListener('click', (e) => {
          if (!e.target.closest('.tbSearchBox')) {
            this.suggestionsList.classList.remove('visible');
            this.searchbox.classList.remove('visible');
          }
          
        });
      }
    });

    this.searchbox.addEventListener('keydown', e => {
      
      if (e.code === 'Enter' && e.target.value.length > 1)  
        this.search(e.target.value);

        if (e.code === 'ArrowDown') {
          e.preventDefault();
          setTimeout(() => this.suggestionsList.querySelector('a').focus(),1000);
          this.suggestionsList.classList.add('visible');
          this.suggestionsList.querySelector('a').focus();
        }
    });

    this.suggestionsList.addEventListener('keydown', e => {

      if (e.code === 'ArrowDown') {
        console.log(e.target.nextElementSibling);
        if(e.target.nextElementSibling) {
          e.target.nextElementSibling.focus();
          e.preventDefault();
        }
    
      }
      if (e.code === 'ArrowUp') {
        if (e.target.previousElementSibling) {
          e.target.previousElementSibling.focus();
          e.preventDefault();
        }
      }
    });
  }
  boxLogic(hide) {
    const value = this.searchbox.value;
    if (value.length < 2) {
      this.suggestionsList.innerHTML = '';
      this.suggestionsList.classList.remove('visible');
      this.searchbox.classList.remove('visible');
      return;
    }
    const url = this.typeAheadUrl;
    axios
      .post(url, {
        query: value,
      })
      .then((response) => {
        this.suggestionsList.innerHTML = '';

        if (response.data.Results.length) {
          if (!hide) {
            this.suggestionsList.classList.add('visible');

            document.addEventListener('click', (e) => {
              if (!e.target.closest('.lstSuggestions')) {
                this.suggestionsList.classList.remove('visible');
                this.searchbox.classList.remove('visible');
              }
            }, { once: true});
          }
          this.searchbox.classList.add('visible');
        }
        else {
          this.suggestionsList.classList.remove('visible');
          this.searchbox.classList.remove('visible');
        }
        response.data.Results.forEach((suggestion) => {
          let a = document.createElement('a');
          a.classList = "hover:no-underline";
          a.href = "#";
          a.innerText = suggestion;
          this.suggestionsList.append(a);
          a.addEventListener('click', () => {
            this.search(suggestion);
          });
        });
      });
  }
  getParamsObj() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const paramObj = {};
    for (const value of params.keys()) {
      paramObj[value] = params.get(value);
    }
    return paramObj;
  }

  search(input) {

    this.searchbox.value = input;
    this.suggestionsList.classList.remove('visible');
    if (this.el.getAttribute('data-search') === 'page' && document.doSearch)
      document.doSearch(input);
    else if (!this.getParamsObj()['term']) {
      const url = this.el.getAttribute('data-url')+`&term=${input}`;
      window.location = url;
    }
  }
}
export default class Accordion {
  constructor(element) {

    this.el = element;
    this.id = this.el.querySelector('.accordion-item').getAttribute('data-id');
    this.init();
  }

  init() {
    this.el.querySelectorAll('*[data-id="' + this.id + '"] > .accordion-item__header').forEach(item => {

      const content = item.parentNode.querySelector('*[data-id="' + this.id + '"] .accordion-item__content');
      item.addEventListener('click', () => {
        let height = content.getBoundingClientRect().height;

        content.style.height = '0px';
        let skip = false;
        if (content.classList.contains('loaded') && height == '0') {
          content.classList.remove('loaded');
          content.style.transition = '';

          setTimeout(() => {
            content.style.height = 'auto';
            skip = true;
          }, 5);
        }

        content.classList.remove('loading');

        if (content.classList.contains('loaded')) {
          height = 0;
          content.addEventListener('transitionend', () => {
            if (skip) {
              content.classList.remove('loaded');
              content.classList.add('loading');
              content.style.height = 'auto';
              content.style.transition = '';
            }

            skip = false;
          }, { once: true });
        }

        setTimeout(() => {

          content.style.transition = 'height ease 0.4s';

          // special case for accordion in accordion
          const parentAccordion = this.el.closest('.accordion-item__content');

          if (parentAccordion) {
            setTimeout(() => {
              console.log(this.el.closest('.accordion-item__content'));
              height = 'auto';
              parentAccordion.style.transition = 'none';
              parentAccordion.style.height = 'px';
              console.log(parentAccordion);
            }, 2 );
          }

          content.style.height = height + 'px';
          content.classList.add('loaded');
        },2);

        content.classList.toggle('accordion-item__content-hidden');
        item.classList.toggle('accordion-item__header-visible');
      });
    });
  }
}
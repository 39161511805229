import axios from 'axios';
import paginationBuilder from '../../util/paginationBuilder';
import { format } from 'date-fns';
import { getParamsObj, updateBrowserState, setSelectOptions } from '../../util/browserUtil';

export default class Publicationlist {
  constructor(element) {
    this.el = element;
    this.subjects = [];
    this.publicationTypes = [];
    this.page = 1;
    this.subjectPicker = null;
    this.typePicker = null;
    this.datePicker = null;
    this.params = null;
    this.page = 1;
    this.numberOfPages = 100;
    this.data = {};
    this.publicationItemHtml = null;
    this.publicationItems = null;
    this.dates = [];
    this.init();
  }
  // manage changes to this.data (change filter states, publication etc.)
  set _data(value) {
    this.data = value;
    if (value?.Tags) {
      const subjectTypes = value.Tags.Nodes.filter(x => x.RootAlias === 'Emner');
      const publicationTypes = value.Tags.Nodes.filter(x => x.RootAlias === 'PublikationsTyper');
      const dateTags = value.Tags.Nodes.filter(x => x.RootAlias === 'dates');
      this.subjectPicker.Update(subjectTypes, this.subjects, this.subjectsChangedHandler);
      this.typePicker.Update(publicationTypes, this.publicationTypes, this.typesChangedHandler);
      this.datePicker.Update(dateTags, this.dates, this.datesChangedHandler);
    }
    this.numberOfPages = value.SearchResult.Paging.PagesToShow.length;
    this.loadpublication(value.SearchResult.Items);
    this.managePagination();
  }
  init() {
    // Element getters
    this.subjectPicker = this.el.querySelector('#filterSubject');
    this.typePicker = this.el.querySelector('#filterType');
    this.datePicker = this.el.querySelector('#filterDates');

    // load reference html and remove from dom
    this.publicationItemHtml = this.el.querySelector('#publicationItemPlaceholder');
    this.publicationItemHtml.parentElement.removeChild(this.publicationItemHtml);
    this.publicationItemHtml.removeAttribute('id');

    this.publicationItems = this.el.querySelector('.publicationItems');
    this.manageState();

    this.subjectsChangedHandler = (selectedSubjects)=> {
      this.subjects = selectedSubjects;
      updateBrowserState(this.nextUrl(true));
      this.manageState();
    };
    this.typesChangedHandler = (selectedTypes)=> {
      this.publicationTypes = selectedTypes;
      updateBrowserState(this.nextUrl(true));
      this.manageState();
    };
    this.datesChangedHandler = (selectedDates) => {
      this.dates = selectedDates;
      updateBrowserState(this.nextUrl(true));
      this.manageState();
    }
  }

  loadpublication(items) {
    this.publicationItems.innerHTML = '';
    console.log(items);
    items.forEach(item => {

      const publicationItem = this.publicationItemHtml.cloneNode(true);
      publicationItem.querySelector('.publicationItem__headline').innerText = item.Headline;
      publicationItem.querySelector('.publicationItem__url').href = item.Url;

      if(item.Description?.length > 0){
        publicationItem.querySelector('.publicationItem_description').innerText = item.Description;
      }
      else{
        publicationItem.querySelector('.publicationItem_description').remove();
      }

      if (item.ImageUrls?.Items.length) {
        const img = item.ImageUrls.Items.find(x => x.Alias == 'Tile');
        if (img) {
          publicationItem.querySelector('.publicationItem__image').src = `${window.location.origin}/${img.Url}`;
          publicationItem.querySelector('.publicationItem__image').alt = img.Alt;
          console.log(img.Url);
        }
        else {
          publicationItem.querySelector('.publicationItem__image').remove();
        }
      }
      else{
        publicationItem.querySelector('.publicationItem__image').src = '/assets/icons/naal_placeholder.svg';
      }
      publicationItem.querySelector('.publicationItem__date').innerText = format(Date.parse(item.Date), 'dd.MM.yyyy');
      this.publicationItems.appendChild(publicationItem);
    });
  }

  manageState() {
    this.manageParams();

    var additionalFilters = new Array();
    // Get Pre selected department if any
    if (this.el.dataset.department) {
      const formattedDepartment = this.el.dataset.department.replace(/[{}]/g, '').toLowerCase();
      additionalFilters.push(formattedDepartment);
    }

    let url = `${window.location.origin}/api/publication/PublicationListJson?types=${this.publicationTypes.join('|')}&subjects=${this.subjects.join('|')}&dates=${this.dates.join('|')}&page=${this.page}&additionalFilter=${additionalFilters.join('|')}`;
    if(this.el.dataset.options && this.el.dataset.options.length > 0){
      url = `${url}&optionId=${this.el.dataset.options}`;
    }

    axios.get(url).then(response => {
      this._data = response.data;
      if (this.data) {
        const subjectTypes = this.data.Tags.Nodes.filter(x => x.RootAlias === 'Emner');
        const publicationTypes = this.data.Tags.Nodes.filter(x => x.RootAlias === 'PublikationsTyper');
        const dateTags = this.data.Tags.Nodes.filter(x => x.RootAlias === 'dates');
        this.subjectPicker.Update(subjectTypes, this.subjects, this.subjectsChangedHandler);
        this.typePicker.Update(publicationTypes, this.publicationTypes, this.typesChangedHandler);
        this.datePicker.Update(dateTags, this.dates, this.datesChangedHandler);
      }
    });
  }

  manageParams() {
    this.params = getParamsObj();
    if (this.params.types) {
      this.publicationTypes = this.params.types.split('|');      
      this.publicationTypes = this.publicationTypes.filter(x => x != '');
    }
    if(this.params.subjects){
      this.subjects = this.params.subjects.split('|');
      this.subjects = this.subjects.filter(x => x != '');
    }
    if (this.params.page) {
      this.page = parseInt(this.params.page);
    }
    if (this.params.dates) {
      this.dates = this.params.dates.split('|');
    }
  }

  managePagination() {
    if (this.numberOfPages == 1) {
      if (this.el.querySelector('.pagination'))
        this.el.querySelector('.pagination').classList.add('hidden');
      return;
    }
    this.el.querySelector('.pagination').classList.remove('hidden');
    const callback = e => {
      const value = e.target.closest('a[data-value]').getAttribute('data-value');
      if (value) {
        if (value == 'prev') this.page--;
        else if (value == 'next') this.page++;
        else this.page = parseInt(value)+1;
        paginationBuilder(this.el.querySelector('.pagination'), this.page, this.numberOfPages, callback);
      }
      updateBrowserState(this.nextUrl());
      this.manageState();
      e.preventDefault();
    };

    paginationBuilder(this.el.querySelector('.pagination'), this.page, this.numberOfPages, callback);
  }

  nextUrl(resetPage = false) {
    if (resetPage) {
      this.page = 1;
    }
    let nextUrl = `?types=${this.publicationTypes.join('|')}&subjects=${this.subjects.join('|')}&page=${this.page}`;
    if (this.dates) {
      nextUrl += `&dates=${this.dates.join('|')}`;
    }
    return nextUrl;
  }
}

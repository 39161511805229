export default class DropdownTree {
    constructor(element) {
      this.el = element;
      this.select = null;
      this.list = null;
      this.optionHtml = null;
      this.label = null;
      this.labelText = null;
      this.labelArray = [];
      this.rootNodes = null;
      this.openedChevronIds = [];
      this.init();
    }
  
    init() {
        this.listContainer = this.el.querySelector('.checkbox-list-container');
        this.dropdownContainer = this.el.querySelector('.dropdown-container');
        this.label = this.el.querySelector('.dropdown-label');
        this.labelText = this.label.innerText;
        this.itemHtml = this.listContainer.querySelector('.checkbox-list-item');
        this.itemHtml.parentElement.removeChild(this.itemHtml);
        this.listHtml = this.listContainer.querySelector('.dropdown-list');
        this.listHtml.parentElement.removeChild(this.listHtml);
        this.el.Update = this.UpdateUiTree;

        this.el.addEventListener('click', () => {
            const container = this.el.querySelector('.dropdown-container');
      
            container.classList.toggle('hidden');
            container.style.height = `${this.listContainer.offsetHeight}px`; 
            if (!container.classList.contains('hidden')) {
              this.el.querySelector('.dropdown-chevron').style.transform = 'rotate(180deg)';
              setTimeout(() => {
                document.addEventListener('click', e => {
                  if (!e.target.closest('.dropdown')) {
                    document.querySelectorAll('.dropdown-container').forEach(d => d.classList.add('hidden'));
                    document.querySelectorAll('.dropdown-chevron').forEach(d => d.style.transform = 'rotate(0deg)');
                  }
                }, {once: true});
              },50);
            }
            else {
              this.el.querySelector('.dropdown-chevron').style.transform = 'rotate(0deg)';
              container.style.height = 0;
            }
          });

          this.el.Update = (nodeList, selectedValues, changedHandler) => {
            this.rootNodes = nodeList;
            this.listContainer.innerHTML = '';
            this.CreateList(nodeList, selectedValues, this.listContainer, this.listHtml, this.itemHtml, this.rootNodes, changedHandler, false, 1);
            this.SetIndeterminateCheckStateForNodeList(nodeList);
            this.OpenChevronsForSelected(nodeList);
            const container = this.el.querySelector('.dropdown-container');
            container.style.height = `${this.listContainer.offsetHeight}px`;       
        }
    }


    CreateList(nodeList, selectedValues, parentContainer, listTemplate, itemTemplate, rootNodes, changedHandler, ancestorSelected, currentDepth){
        const newList = listTemplate.cloneNode(true);
        if(currentDepth > 1){
            newList.setAttribute("style", "padding-left:20px;");
            newList.classList.add("hidden");
        }
        parentContainer.appendChild(newList);
        nodeList.forEach((node) => {
            this.CreateListItem(node, selectedValues, newList, listTemplate, itemTemplate, rootNodes, changedHandler, ancestorSelected, currentDepth);
        });
        return newList;
    }

    CreateListItem(node, selectedValues, parentContainer, listTemplate, itemTemplate, rootNodes, changedHandler, ancestorSelected, currentDepth){
        const newListItem = itemTemplate.cloneNode(true);
        parentContainer.appendChild(newListItem);

        let headlineSpan = newListItem.querySelector('span');
        headlineSpan.innerText = node.Headline;

        let chevron = newListItem.querySelector(".nestedChevron");

        const checkbox = newListItem.querySelector('input[type="checkbox"]');
        checkbox.type = 'checkbox';
        if (!node.Id) node.Id = node.Name; // Fix for years not having an ID attached.
        checkbox.setAttribute('data-id', node.Id);
        node.checkbox = checkbox;
        if(ancestorSelected || selectedValues.includes(node.Id)){
            checkbox.checked = true;
        } else {
            //console.log(newListItem);
        }

        checkbox.addEventListener('click', (event) => {
            let clickedNodeId = event.target.getAttribute('data-id');
            let clickedNode = this.FindNodeInListRecursively(clickedNodeId, rootNodes);
            this.CheckboxClicked(rootNodes, changedHandler, clickedNode);
            event.stopPropagation();
            return false;
        });

        headlineSpan.addEventListener('click', (event => {
            event.stopPropagation();
            return false;
        }));
        if(node.Children.length > 0){
            let firstChrevron = newListItem.querySelector('.nestedChevron');
            firstChrevron.classList.remove('hidden');
            firstChrevron.addEventListener('click', (event) => {
                event.stopPropagation();
                const container = this.el.querySelector('.dropdown-container');
                if ((event.target.style.transform == 'rotate(0deg)' || !event.target.style.transform)) {
                    firstChrevron.style.transform = 'rotate(180deg)';
                }
                else if (event.target.style.transform == 'rotate(180deg)') {
                    firstChrevron.style.transform = '';
                }
                event.target.closest('li').querySelector('ul').classList.toggle('hidden');
                container.style.height = `${this.listContainer.offsetHeight}px`;

            })
            let nodeChildList = this.CreateList(node.Children, selectedValues, newListItem, listTemplate, itemTemplate, rootNodes, changedHandler, checkbox.checked, currentDepth+1);
            node.childList = nodeChildList;
        }
    }

    FindNodeInListRecursively(nodeId, nodeList)
    {
        let result = null;
        nodeList.forEach((node)=>{
            if(result == null){
                result = this.FindNodeInSubtreeRecursively(nodeId, node);
            }
        });

        return result;
    }

    FindNodeInSubtreeRecursively(nodeId, currentNode){        
        if(currentNode.Id == nodeId){
            return currentNode;
        }

        if(currentNode.Children.length > 0){
            return this.FindNodeInListRecursively(nodeId, currentNode.Children);
        }
        return null;
    }

    CheckboxClicked(rootNodes, changedHandler, clickedNode){
        let selectedIds = [];
        if(!clickedNode.checkbox.checked){
            this.UncheckAncestors(rootNodes, clickedNode.Id);
            this.UncheckDescendants(rootNodes, clickedNode.Id);
        }        
        this.GetSelectedIdsRecursively(rootNodes, selectedIds);
        this.openedChevronIds = this.GetOpenedChevrons(rootNodes);
        changedHandler(selectedIds);
    }

    GetSelectedIdsRecursively(nodeList, resultList){
        nodeList.forEach((node) => {
            if(node.checkbox.checked){
                resultList.push(node.checkbox.getAttribute('data-id'));
            }
            else if(node.Children.length > 0)
            {
                this.GetSelectedIdsRecursively(node.Children, resultList);
            }
        });       

    }

    OpenChevronsForSelected(nodeList){
        let allNodes = new Array();
        this.GetAllNodesRecursively(nodeList, allNodes);
        // console.log(this.openedChevronIds);
        allNodes.forEach((node)=>{
            if(node.childList && (this.openedChevronIds.includes(node.Id) || node.subtreeState == 'AllChecked' || node.subtreeState == 'SomeChecked')){
                // console.log("Remove hidden for");
                // console.log(node);
                let chevron = node.checkbox.parentElement.parentElement.querySelector(".nestedChevron");
                if (!chevron.classList.contains("hidden")) {
                    chevron.style.transform = "rotate(180deg)";
                }
                node.childList.classList.remove('hidden');    
            }
        });                
    }

    GetOpenedChevrons(nodeList){
        let resultList = new Array();
        let allNodes = new Array();
        this.GetAllNodesRecursively(nodeList, allNodes);
        allNodes.forEach((node)=>{
            if(node.childList && !node.childList.classList.contains('hidden')){
                resultList.push(node.Id);
            }
        });

        return resultList;
    }

    GetAllNodesRecursively(nodeList, resultList){
        nodeList.forEach((node) => {
            resultList.push(node);
            if(node.Children.length > 0)
            {
                this.GetAllNodesRecursively(node.Children, resultList);
            }
        });
    }

    UncheckAncestors(rootNodes, nodeIdToFind){
        let ancestorList = new Array();
        //console.log('uncheck ancestors: ' + nodeIdToFind);

        if(this.GetAncestorsRecursivelyFromList(rootNodes, nodeIdToFind, ancestorList)){
            //console.log(ancestorList);
            ancestorList.forEach((node)=>{
                node.checkbox.checked = false;
            });
        }
    }

    UncheckDescendants(rootNodes, nodeIdToFind){
        let descendantList = new Array();
        //console.log('uncheck descendants: ' + nodeIdToFind);

        if(this.GetDescendantsRecursiveleyFromList(rootNodes, nodeIdToFind, descendantList, false)){
           // console.log(descendantList);
            descendantList.forEach((node)=>{
                node.checkbox.checked = false;
            });
        }        
    }

    GetAncestorsRecursivelyFromList(rootNodes, nodeIdToFind, ancestorList){
        let found = false;
        rootNodes.forEach((node) => {
            if(found == false){
                found = this.GetAncestorsRecursivelyFromSubtree(node, nodeIdToFind, ancestorList);
            }
        });

        return found;
    }

    GetAncestorsRecursivelyFromSubtree(rootNode, nodeIdToFind, ancestorList){
        if(rootNode.Id == nodeIdToFind){
            return true;
        }
        
        if(rootNode.Children.length > 0){
            if(this.GetAncestorsRecursivelyFromList(rootNode.Children, nodeIdToFind, ancestorList)){
                ancestorList.push(rootNode);
                return true;
            }
        }

        return false;
    }

    GetDescendantsRecursiveleyFromList(rootNodes, nodeIdToFind, descendantList, isDescendant){
        let found = false;
        rootNodes.forEach((node) => {
            if(this.GetDescendantsRecursiveleyFromSubtree(node, nodeIdToFind, descendantList, isDescendant)){
                found = true;
            }
        });

        return found;
    }

    GetDescendantsRecursiveleyFromSubtree(rootNode, nodeIdToFind, descendantList, isDescendant){
        if(isDescendant){
            descendantList.push(rootNode);
        }        
        let found = isDescendant || rootNode.Id == nodeIdToFind;

        if(rootNode.Children.length > 0){
            found = this.GetDescendantsRecursiveleyFromList(rootNode.Children, nodeIdToFind, descendantList, found)
        }

        //console.log(rootNode.Headline + ' - IsDescendant: [' + isDescendant + '] - found ['+found+']')
        return found;
    }

    // Returns either 'AllChecked", "SomeChecked", "NoChecked"
    SetIndeterminateCheckStateForNodeList(nodeList){
        let state = '';
        nodeList.forEach((node) => {
            let subtreeState = this.SetIndeterminateCheckStateForSubtree(node);
            //console.log('NodeList ' + node.Headline + 'before[subtree ' + subtreeState + '], [state' + state + ']');
            //console.log(node.Headline + "[" + subtreeState + "]");
            if(state == ''){
                state = subtreeState;
            }
            else if(state != subtreeState) {
                state = 'SomeChecked';
            }
        });

        //console.log('NodeList after-and-returning[' + state + ']');

        return state;
    }

    // Returns either 'AllChecked", "SomeChecked", "NoChecked"
    SetIndeterminateCheckStateForSubtree(subtreeRootNode){
        //console.log(subtreeRootNode.Headline + " IndeterminateForSubtreeStart");
        let state = '';
        if(subtreeRootNode.Children.length > 0){
            state = this.SetIndeterminateCheckStateForNodeList(subtreeRootNode.Children);
            //console.log('Subtree ' + subtreeRootNode.Headline + " - DescendantsState [" + state + "]");
            if(state == 'AllChecked'){
                subtreeRootNode.checkbox.checked = true;
                subtreeRootNode.subtreeState = state;
                //console.log('Subtree Returning - ' + state + ' All');
                return state;
            }
            else if(state == 'SomeChecked'){
                subtreeRootNode.checkbox.indeterminate = true;
                subtreeRootNode.subtreeState = state;
                //console.log('setting indeterminate on :' + subtreeRootNode.Headline);
                //console.log('Subtree Returning - ' + state + ' SOME');
                return state;
            }
            
            //console.log('SubTree Returning NoChecked - ' + state + ' (not some or all)');
            subtreeRootNode.subtreeState = 'NoChecked';
            return 'NoChecked';
        }
        
        if(subtreeRootNode.checkbox.checked){
            //console.log('Subtree ' + subtreeRootNode.Headline + " Returning AllChecked");
            subtreeRootNode.subtreeState = 'AllChecked';
            return 'AllChecked';
        }

        //console.log('Subtree ' + subtreeRootNode.Headline + " Returning NoChecked");
        subtreeRootNode.subtreeState = 'NoChecked';
        return "NoChecked";
    }
  }
  
import axios from 'axios';
import paginationBuilder from '../../util/paginationBuilder';
import { format } from 'date-fns';
import { getParamsObj, updateBrowserState, setSelectOptions } from '../../util/browserUtil';

export default class NewsList {
  constructor(element) {
    this.el = element;
    this.datePicker = null;
    this.subjects = [];
    this.page = 1;
    this.subjectPicker = null;
    this.params = null;
    this.tags = '';
    this.numberOfPages = 100;
    this.data = {};
    this.newsItemHtml = null;
    this.newsItems = null;
    this.dates = [];
    this.init();
  }
  // manage changes to this.data (change filter states, news etc.)
  set _data(value) {
    this.data = value;
    if (value?.Tags) {
      const subjectTags = this.data.Tags.Nodes.filter(x => x.RootAlias === 'Emner');
      this.subjectPicker.Update(subjectTags, this.subjects, this.subjectsChangedHandler);
      const datesTags = this.data.Tags.Nodes.filter(x => x.RootAlias === 'dates');
      this.datePicker.Update(datesTags, this.dates, this.datesChangeHandler);
    }
    this.numberOfPages = value.SearchResult.Paging.PagesToShow.length;
    this.loadNews(value.SearchResult.Items);
    this.managePagination();
  }
  init() {
    // Element getters
    this.subjectPicker = this.el.querySelector('#filterSubject');
    this.datePicker = this.el.querySelector("#filterDates");

    // load reference html and remove from dom
    this.newsItemHtml = this.el.querySelector('#newsItemPlaceholder');
    this.newsItemHtml.parentElement.removeChild(this.newsItemHtml);
    this.newsItemHtml.removeAttribute('id');

    this.newsItems = this.el.querySelector('.newsItems');
    this.manageState();

    var currentComponent = this;
    window.addEventListener('resize', function(event){
      currentComponent.onViewportResize(event);
    }, true);    

    this.subjectsChangedHandler = (selectedSubjects)=> {
      this.subjects = selectedSubjects;
      updateBrowserState(this.nextUrl(true));
      this.manageState();
    };   
    
    this.datesChangeHandler = (selectedYears) => {
      this.dates = selectedYears;
      updateBrowserState(this.nextUrl(true));
      this.manageState();
    }
  }

  loadNews(items) {
    this.newsItems.innerHTML = '';

    items.forEach(item => {
      const newsItem = this.newsItemHtml.cloneNode(true);
      newsItem.querySelector('.newsItem__headline').innerText = item.Headline;
      newsItem.querySelector('.newsItem__text').innerText = item.Description;
      newsItem.setAttribute('data-id', item.Id);
      newsItem.querySelector('.newsItem__url').href = item.Url;

      if (item.Tags.Items?.length) {
        const newsSubjects = newsItem.querySelector('.newsItem__subject');
        newsSubjects.innerText = item.Tags.Items.map(s => s.Headline).join(', ');
      }

      if (item.ImageUrls?.Items.length) {
        const img = item.ImageUrls.Items.find(x => x.Alias == 'List');
        if(img){
          newsItem.querySelector('.newsItem__image').alt = img.Alt;
        }          
      }
      newsItem.querySelector('.newsItem__date').innerText = format(Date.parse(item.NewsDate), 'dd.MM.yyyy');
      this.newsItems.appendChild(newsItem);
      this.setImageCrop(item, newsItem);
    });
  }

  onViewportResize(event){
    if(this.data?.SearchResult){
      const domItems = [...document.querySelectorAll('.newsItem')];
      domItems.forEach(domItem => {
        var itemId = domItem.getAttribute('data-id');
        var dataItem = this.data.SearchResult.Items.find(item => item.Id == itemId);
        this.setImageCrop(dataItem, domItem);
      });
    }
    else{
      console.log('no data');
    }
  }

  setImageCrop(dataItem, domItem){
    if(dataItem){

      if(dataItem.ImageUrls?.Items.length){
        // Default
        const img = dataItem.ImageUrls.Items.find(x => x.Alias == 'List');
        domItem.querySelector('.newsItem__image').src = img.Url;

        // Adjust according to current offset
        if(domItem.offsetWidth < 768){
          const imgMedium = dataItem.ImageUrls.Items.find(x => x.Alias == 'Tile');
          domItem.querySelector('.newsItem__image').src = imgMedium.Url;        
        }
        else if(domItem.offsetHeight > 150 && domItem.offsetHeight <= 180){
          const imgMedium = dataItem.ImageUrls.Items.find(x => x.Alias == 'ListMedium');
          domItem.querySelector('.newsItem__image').src = imgMedium.Url;
        }        
        else if(domItem.offsetHeight > 180 && domItem.offsetHeight <= 225){
          const imgMedium = dataItem.ImageUrls.Items.find(x => x.Alias == 'ListLarge');
          domItem.querySelector('.newsItem__image').src = imgMedium.Url;
        }
        else if(domItem.offsetHeight > 225){
          const imgLarge = dataItem.ImageUrls.Items.find(x => x.Alias == 'Tile');
          domItem.querySelector('.newsItem__image').src = imgLarge.Url;
        }
      }
      else if(domItem.querySelector('.newsItem__image')){
          domItem.querySelector('.newsItem__image').remove();
      }
    }
    else{
      console.log('dataitem is null');
    }
  }

  manageState() {
    this.manageParams();
    let url = `${window.location.origin}/api/NewsComponent/NewsListJson?subjects=${this.subjects.join('|')}&page=${this.page}&dates=${this.dates.join('|')}`;

    axios.get(url).then(response => {
      this._data = response.data;
      if (this.data) {
        const subjectTypes = this.data.Tags.Nodes.filter(x => x.RootAlias === 'Emner');
        this.subjectPicker.Update(subjectTypes, this.subjects, this.subjectsChangedHandler);        
        const dates = this.data.Tags.Nodes.filter(x => x.RootAlias === 'dates');
        this.datePicker.Update(dates, this.dates, this.datesChangeHandler);
      }
    });
  }

  manageParams() {
    this.params = getParamsObj();
    if (this.params.subjects) {
      this.subjects = this.params.subjects.split('|');
    }
    if (this.params.page) {
      this.page = parseInt(this.params.page);
    }
    if (this.params.dates) {
      this.dates = this.params.dates.split('|');
    }
  }

  managePagination() {
    if (this.numberOfPages == 1) {
      if (this.el.querySelector('.pagination'))
        this.el.querySelector('.pagination').classList.add('hidden');
      return;
    }
    this.el.querySelector('.pagination').classList.remove('hidden');
    const callback = e => {
      const value = e.target.closest('a[data-value]').getAttribute('data-value');
      if (value) {
        if (value == 'prev') this.page--;
        else if (value == 'next') this.page++;
        else this.page = parseInt(value)+1;
        paginationBuilder(this.el.querySelector('.pagination'), this.page, this.numberOfPages, callback);
      }
      updateBrowserState(this.nextUrl());
      this.manageState();
      e.preventDefault();
    };

    paginationBuilder(this.el.querySelector('.pagination'), this.page, this.numberOfPages, callback);
  }

  nextUrl(resetPage = false) {
    if (resetPage) {
      this.page = 1;
    }
    let nextUrl = `?subjects=${this.subjects.join('|')}&page=${this.page}`;
    if (this.dates) {
      nextUrl += `&dates=${this.dates.join('|')}`;
    }
    return nextUrl;
  }
}

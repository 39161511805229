import 'regenerator-runtime';
import './index.css';
import {EventBus, EventTypes} from './consts/const-event-types';
/*
To add new components, import them here and add them to the 'components'-array below.
Add an appropriate selectorName for use in DOM (fx: <div data-component="header"></div>):
*/
import App from './components/app';
import Slider from './components/slider/Slider';
import Menu from './components/menu/Menu';
import NewsList from './components/newslist/newslist';
import PublicationList from './components/publicationlist/publicationlist';
import Dropdown from './components/dropdown/dropdown';
import DropdownTree from './components/dropdowntree/dropdowntree';
import Datepicker from './components/datepicker/datepicker';
import EventsList from './components/eventslist/eventslist';
import CalendarArchive from './components/calendararchive/calendararchive';
import ContactTree from './components/contacttree/contacttree';
import FWSlider from './components/slider/fwSlider';
import Hearings from './components/hearingcomponent/hearingcomponent';
import Searchbox from './components/searchbox/searchbox';
import Search from './components/search/search';
import Youtube from './components/youtube/youtube';
import Accordion from './components/accordion/accordion';
import NewyearSpeeches from './components/newyear-speeches/newyear-speeches';
import MeetingMinutes from './components/meeting-minutes/meeting-minutes';
import FocusList from './components/focuslist/focuslist';
import VacancyList from './components/vacancylist/vacancylist';
import Tooltip from './components/tooltip/tooltip';
import LanguagePicker from './components/languagePicker/languagePicker';

const components = [
  {Class: App, selectorName: 'app'},
  {Class: Slider, selectorName: 'slider'},
  {Class: FWSlider, selectorName: 'fullwidthSlider'},
  {Class: Menu, selectorName: 'menu'},
  {Class: NewsList, selectorName: 'newslist'},
  {Class: PublicationList, selectorName: 'publicationlist'},
  {Class: Dropdown, selectorName: 'dropdown'},
  {Class: DropdownTree, selectorName: 'dropdowntree'},
  {Class: Datepicker, selectorName: 'datepicker'},
  {Class: EventsList, selectorName: 'eventslist'},
  {Class: CalendarArchive, selectorName: 'calendararchive'},
  {Class: ContactTree, selectorName: 'contacttree'},
  {Class: Searchbox, selectorName: 'searchbox'},
  {Class: Search, selectorName: 'search'},
  {Class: Hearings, selectorName: 'hearings'},
  {Class: Youtube, selectorName: 'youtube'},
  {Class: Accordion, selectorName: 'accordion'},
  {Class: NewyearSpeeches, selectorName: 'newyear-speeches'},
  {Class: MeetingMinutes, selectorName: 'meeting-minutes'},
  {Class: FocusList, selectorName: 'focuslist'},
  {Class: VacancyList, selectorName: 'vacancylist'},
  {Class: Tooltip, selectorName: 'tooltip'},
  {Class: LanguagePicker, selectorName: 'langPicker'}
];

/*
* ComponentInstantiator traverses the DOM-tree and looks for [data-component='] attributes.
*/
class ComponentInstantiator {
  constructor() {
    this.createComponents();
    this.dispatchReady();
    return this;
  }

  createComponents() {
    this.componentsInstantiated = {};

    components.map(component => {
      const nodes = [];
      // select all DOM-nodes with the data-component attribute:
      const nodeItems = [...document.querySelectorAll('[data-component=\'' + component.selectorName + '\']')];

      nodeItems.map(nodeItem => {
        // instantiate component with nodeItem as param:
        nodes.push(new component.Class(nodeItem));
      });

      // add to componentsList object:
      if (nodes.length) this.componentsInstantiated[component.selectorName] = nodes;
    });
  }

  dispatchReady() {
    const event = new CustomEvent(EventTypes.ALL_COMPONENTS_READY);
    EventBus.dispatchEvent(event);
  }

  getComponents(selectorName) {
    return selectorName ? this.componentsInstantiated[selectorName] : this.componentsInstantiated;
  }
}

/* Make your App accessible via window.App */
window.App = new ComponentInstantiator();

/* Access components list */
// console.log('components on this page', window.App.getComponents())

// various global js functions
//InitSitecoreTables();

import axios from 'axios';
import { format } from 'date-fns';
export default class VacancyList {
  constructor(element) {
    this.el = element;
    this.init();
  }

  init() {
    console.log('Example-component initiated with this element', this.el);

    const vacancyItemHtml = this.el.querySelector('#vacancyItemPlaceholder');
    vacancyItemHtml.parentElement.removeChild(vacancyItemHtml);
    vacancyItemHtml.removeAttribute('id');
    const vacancyItems = this.el.querySelector('.vacancies');

    const url = `${window.location.origin}/api/Vacancy/GetVacanciesJson?pageSize=${5}&page=${1}`;
    axios.get(url).then((res) => {
      res.data.Vacancies.forEach((vacancy) => {
        console.log(vacancy);
        const vacancyItem = vacancyItemHtml.cloneNode(true);
        vacancyItem.querySelector('.vacancyItem__headline').innerText = vacancy.VacancyName;
        vacancyItem.querySelector('.vacancyItem__location').innerText = vacancy.VacancyLocation;
        vacancyItem.href = `//${vacancy.VacancyUrl}`;
        vacancyItem.querySelector('.vacancyItem__date').innerText = format(Date.parse(vacancy.VacancyTimeLimit), 'dd.MM.yyyy');
        vacancyItems.appendChild(vacancyItem);
      });
    });
  }
}

import Litepicker from 'litepicker';
import { format } from 'date-fns';

export default class Dropdown {
  constructor(element) {
    this.el = element;
    this.picker = null;
    this.pickerElement = null;
    this.init();
    this.width = 0;
    this.open = false;
    this.dictionary = {};
  }

  init() {
    this.pickerElement = this.el.querySelector('.litepicker-input');
    this.dictionary = {
      day: this.pickerElement.getAttribute('data-dictionary-day'),
      days: this.pickerElement.getAttribute('data-dictionary-days'),
    };

    this.pickerElement.style.display = 'none';

    this.picker = new Litepicker({
      element: this.pickerElement,
      //parentEl: this.el.querySelector('.litepicker-div'),
      singleMode: false,
      lang: 'da',
      tooltipText: {
        one: this.dictionary.day,
        other: this.dictionary.days,
      },
      dropdowns: {'minYear':1990,'maxYear':null,'months':true,'years':true},
      resetButton: true,
      setup: (picker) => {
        picker.on('selected', (date1, date2) => {
          this.el.querySelector('.datepicker-label').innerHTML= `${format(date1.dateInstance, 'dd.MM.yy')} &#8594; ${format(date2.dateInstance, 'dd.MM.yy')}`;
          this.pickerElement.dispatchEvent(new Event('datepick'));

        });
        picker.on('clear:selection', () => {
          this.el.querySelector('.datepicker-label').innerText = this.el.querySelector('.datepicker-label').getAttribute('data-text');
          this.pickerElement.dispatchEvent(new Event('datepick'));
        });
        picker.on('hide', () => {
          this.open = false;
          this.el.querySelector('.datepicker-chevron').style.transform = 'rotate(0deg)';
        });
        picker.on('change:month', () => {
          const p = document.querySelector('.litepicker');
          this.manageState(p);
        });
        picker.on('change:year', () => {
          const p = document.querySelector('.litepicker');
          this.manageState(p);
        });
      },
    });
    this.el.addEventListener('click', (e) => {
      if (!e.target.classList.contains('datepicker')) return;
      this.el.querySelector('.datepicker-chevron').style.transform = 'rotate(180deg)';
      const p = document.querySelector('.litepicker');
      p.classList.toggle('open');
      if (p.classList.contains('open')) {
        this.picker.show();

        const rect = e.target.getBoundingClientRect();
        p.style.top = (rect.top-20) + rect.height+'px';
        p.style.left = rect.left+'px';
        const initialHeight = p.getBoundingClientRect().height;

        p.style.height = 0;
        p.style.overflow = 'hidden';
        p.classList.add('transition-all');
        setTimeout(() => {
          p.style.height = initialHeight+'px';
        },50);
        this.width = rect.width;
        this.manageState(p);

      }
      else {
        this.picker.hide();
        this.el.querySelector('.datepicker-chevron').style.transform = 'rotate(0deg)';
      }
    });

    document.querySelector('.litepicker').addEventListener('click', () => {
      const p = document.querySelector('.litepicker');
      this.manageState(p);
    });

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const paramObj = {};
    for (const value of params.keys()) {
      paramObj[value] = params.get(value);
    }
    if (paramObj.from && paramObj.to) {
      this.picker.setDateRange(paramObj.from, paramObj.to);
    }
  }

  manageState(p) {
    p.querySelector('.container__months').style.width = this.width+'px';
    p.querySelector('.month-item').style.width = this.width+'px';
    p.querySelector('.button-previous-month').innerHTML = '&#8592;';
    p.querySelector('.button-next-month').innerHTML = '&#8594;';
  }
}

import axios from 'axios';
import paginationBuilder from '../../util/paginationBuilder';
import { format } from 'date-fns';
import { getParamsObj, updateBrowserState, setSelectOptions } from '../../util/browserUtil';

export default class FocusList {
  constructor(element) {
    this.el = element;
    this.publicationTypes = [];
    this.page = 1;
    this.typePicker = null;
    this.params = null;
    this.tags = '';
    this.page = 1;
    this.numberOfPages = 100;
    this.data = {};
    this.focusItemHtml = null;
    this.focusItems = null;
    this.contextId = this.el.getAttribute('data-contextId');
    this.dates = [];
    this.datePicker = null;
    this.init();
  }
  // manage changes to this.data (change filter states, focus etc.)
  set _data(value) {
    this.data = value;
    if (value?.TagCategoryList) {
      const publicationTypes = value.Tags.Nodes.filter(x => x.RootAlias === 'PublikationsTyper');
      const dateTags = value.Tags.Nodes.filter(x => x.RootAlias === 'dates');
      this.typePicker.Update(publicationTypes, this.publicationTypes, this.typesChangedHandler);
      this.datePicker.Update(dateTags, this.dates, this.datesChangedHandler);
    }
    this.numberOfPages = value.SearchResult.Paging.PagesToShow.length;
    this.loadfocus(value.SearchResult.Items);
    this.managePagination();
  }
  init() {
    // Element getters
    this.subjectPicker = this.el.querySelector('#filterSubject');
    this.typePicker = this.el.querySelector('#filterType');
    this.datePicker = this.el.querySelector('#filterDates');


    // load reference html and remove from dom
    this.focusItemHtml = this.el.querySelector('#focusItemPlaceholder');
    this.focusItemHtml.parentElement.removeChild(this.focusItemHtml);
    this.focusItemHtml.removeAttribute('id');

    this.focusItems = this.el.querySelector('.focusItems');
    this.manageState();

    this.typesChangedHandler = (selectedTypes)=> {
      this.publicationTypes = selectedTypes;
      updateBrowserState(this.nextUrl(true));
      this.manageState();
    };
    this.datesChangedHandler = (selectedDates) => {
      this.dates = selectedDates;
      updateBrowserState(this.nextUrl(true));
      this.manageState();
    }

    var currentComponent = this;
    window.addEventListener('resize', function(event){
      currentComponent.onViewportResize(event);
    }, true);
  }

  loadfocus(items) {
    this.focusItems.innerHTML = '';

    items.forEach(item => {
      const focusItem = this.focusItemHtml.cloneNode(true);
      focusItem.querySelector('.focusItem__headline').innerText = item.Headline;
      focusItem.querySelector('.focusItem__text').innerText = item.Description;
      focusItem.querySelector('.focusItem__url').href = item.Url;
      focusItem.setAttribute('data-id', item.Id);

      if (item.TagCategoryList.Categories.find(s => s.Name == 'PublikationsTyper')) {
        if (item.TagCategoryList.Categories?.length) {
          const focusSubjects = focusItem.querySelector('.focusItem__subject');

          focusSubjects.innerText = item.TagCategoryList.Categories.find(s => s.Name == 'PublikationsTyper').Tags.map(s => s.Headline).join(', ');
        }
      }
      else {
        const focusSubjectsLi = focusItem.querySelector('.focusLiSubject');
        focusSubjectsLi.innerHTML = '';
      }

      focusItem.querySelector('.focusItem__date').innerText = format(Date.parse(item.FocusDate), 'dd.MM.yyyy');
      this.focusItems.appendChild(focusItem);
      this.setImageCrop(item, focusItem);      
    });

  }

  onViewportResize(event){
    if(this.data?.SearchResult){
      const domItems = [...document.querySelectorAll('.focusItem')];
      domItems.forEach(domItem => {
        var itemId = domItem.getAttribute('data-id');
        var dataItem = this.data.SearchResult.Items.find(item => item.Id == itemId);
        this.setImageCrop(dataItem, domItem);
      });
    }
    else{
      console.log('no data');
    }
  }

  setImageCrop(dataItem, domItem){
    if(dataItem){
      if(dataItem.ImageUrls?.Items.length){
        // Default
        const img = dataItem.ImageUrls.Items.find(x => x.Alias == 'List');
        domItem.querySelector('.focusItem__image').src = img.Url;

        // Adjust according to current offset
        if(domItem.offsetWidth < 768){
          const imgMedium = dataItem.ImageUrls.Items.find(x => x.Alias == 'Tile');
          domItem.querySelector('.focusItem__image').src = imgMedium.Url;        
        }
        else if(domItem.offsetHeight > 150 && domItem.offsetHeight <= 180){
          const imgMedium = dataItem.ImageUrls.Items.find(x => x.Alias == 'ListMedium');
          domItem.querySelector('.focusItem__image').src = imgMedium.Url;
        }        
        else if(domItem.offsetHeight > 180 && domItem.offsetHeight <= 225){
          const imgMedium = dataItem.ImageUrls.Items.find(x => x.Alias == 'ListLarge');
          domItem.querySelector('.focusItem__image').src = imgMedium.Url;
        }
        else if(domItem.offsetHeight > 225){
          const imgLarge = dataItem.ImageUrls.Items.find(x => x.Alias == 'Tile');
          domItem.querySelector('.focusItem__image').src = imgLarge.Url;
        }
      }
      else if(domItem.querySelector('.focusItem__image')){
          domItem.querySelector('.focusItem__image').remove();
      }
    }
    else{
      console.log('dataitem is null');
    }
  }

  manageState() {
    this.manageParams();
    let url = `${window.location.origin}/api/FocusElementComponent/FocusListJson?types=${this.publicationTypes.join('|')}&dates=${this.dates.join('|')}&page=${this.page}&settingsitem=${this.contextId}`;

    axios.get(url).then(response => {
      this._data = response.data;
      if (this.data) {
        const publicationTypes = this.data.Tags.Nodes.filter(x => x.RootAlias === 'PublikationsTyper');
        const dateTags = this.data.Tags.Nodes.filter(x => x.RootAlias === 'dates');
        this.typePicker.Update(publicationTypes, this.publicationTypes, this.typesChangedHandler);
        this.datePicker.Update(dateTags, this.dates, this.datesChangedHandler);
      }
    });
  }

  manageParams() {
    this.params = getParamsObj();
    if (this.params.types) {
      this.publicationTypes = this.params.types.split('|');
    }
    if (this.params.page) {
      this.page = parseInt(this.params.page);
    }
    if (this.params.dates) {
      this.dates = this.params.dates.split('|');
    }
  }

  managePagination() {
    if (this.numberOfPages == 1) {
      if (this.el.querySelector('.pagination'))
        this.el.querySelector('.pagination').classList.add('hidden');
      return;
    }
    this.el.querySelector('.pagination').classList.remove('hidden');
    const callback = e => {
      const value = e.target.closest('a[data-value]').getAttribute('data-value');
      if (value) {
        if (value == 'prev') this.page--;
        else if (value == 'next') this.page++;
        else this.page = parseInt(value)+1;
        paginationBuilder(this.el.querySelector('.pagination'), this.page, this.numberOfPages, callback);
      }
      updateBrowserState(this.nextUrl());
      this.manageState();
      e.preventDefault();
    };

    paginationBuilder(this.el.querySelector('.pagination'), this.page, this.numberOfPages, callback);
  }

  nextUrl(resetPage = false) {
    if (resetPage) {
      this.page = 1;
    }
    let nextUrl = `?types=${this.publicationTypes.join('|')}&dates=${this.dates.join('|')}&page=${this.page}`;
    return nextUrl;
  }
}

import axios from 'axios';
import paginationBuilder from '../../util/paginationBuilder';
import { format } from 'date-fns';
import { da } from 'date-fns/locale';
import { getParamsObj, updateBrowserState, setSelectOptions } from '../../util/browserUtil';

export default class Hearings {
  constructor(element) {
    this.el = element;
    this.subjects = [];
    this.page = 1;
    this.subjectPicker = null;
    this.typePicker = null;
    this.statePicker = null;
    this.params = null;
    this.tags = [];
    this.hearingStates = [];
    this.page = 1;
    this.numberOfPages = 100;
    this.data = {};
    this.newsItemHtml = null;
    this.newsItems = null;
    this.dates = [];
    this.datePicker = null;
    this.init();
  }
  // manage changes to this.data (change filter states, news etc.)
  set _data(value) {
    this.data = value;
    if (value?.Tags) {
      const subjectTags = this.data.Tags.Nodes.filter(x => x.RootAlias === 'Hoeringsemner');
      this.subjectPicker.Update(subjectTags, this.subjects, this.subjectsChangedHandler);

      const hearingTypes = value.Tags.Nodes.filter(x => x.RootAlias === 'Hoeringstyper');
      this.typePicker.Update(hearingTypes, this.tags, this.typesChangedHandler);

      const states = value.Tags.Nodes.filter(x => x.RootAlias === 'state');
      this.statePicker.Update(states, this.hearingStates, this.statesChangedHandler);

      const dateTags = this.data.Tags.Nodes.filter(x => x.RootAlias === 'dates');
      this.datePicker.Update(dateTags, this.dates, this.datesChangeHandler);
    }
    this.numberOfPages = value.SearchResult.Paging.PagesToShow.length;
    this.loadNews(value.SearchResult.Items);
    this.managePagination();
  }
  init() {
    // Element getters
    this.subjectPicker = this.el.querySelector('#filterSubject');
    this.typePicker = this.el.querySelector('#filterType');
    this.statePicker = this.el.querySelector('#filterState');
    this.datePicker = this.el.querySelector("#filterDates");

    // load reference html and remove from dom
    this.newsItemHtml = this.el.querySelector('#newsItemPlaceholder');
    this.newsItemHtml.parentElement.removeChild(this.newsItemHtml);
    this.newsItemHtml.removeAttribute('id');

    this.newsItems = this.el.querySelector('.newsItems');
    this.manageState();

    this.datesChangeHandler = (selectedDates) => {
      this.dates = selectedDates;
      updateBrowserState(this.nextUrl(true));
      this.manageState();
    }

    this.subjectsChangedHandler = (selectedSubjects)=> {
      this.subjects = selectedSubjects;
      updateBrowserState(this.nextUrl());
      this.manageState();
    };     

    this.typesChangedHandler = (selectedTypes)=> {
      this.tags = selectedTypes;
      updateBrowserState(this.nextUrl());
      this.manageState();
    };  
        
    this.statesChangedHandler = (selectedStates)=> {
      this.hearingStates = selectedStates;
      updateBrowserState(this.nextUrl());
      this.manageState();
    };
  }

  loadNews(items) {
    this.newsItems.innerHTML = '';

    items.forEach(item => {

      const newsItem = this.newsItemHtml.cloneNode(true);
      newsItem.querySelector('.newsItem__headline').innerText = item.Headline;
      newsItem.querySelector('.newsItem__url').href = item.Url;
      newsItem.querySelector('.hearing__type').innerText = item.Tags.Items.filter(x => x.RootAlias === 'Hoeringstyper').map(s => s.Headline).join(', ');

      if (item.Tags.Items?.length) {
        const newsSubjects = newsItem.querySelector('.newsItem__subject');
        newsSubjects.innerText = item.Tags.Items.filter(x => x.RootAlias === 'Hoeringsemner').map(s => s.Headline).join(', ');
      }

      newsItem.querySelector('.newsItem__date').innerText = format(Date.parse(item.HearingDeadline), 'd. MMMM yyyy', {locale: da});

      this.newsItems.appendChild(newsItem);
    });
  }

  manageState() {
    this.manageParams();
    let url = `${window.location.origin}/api/HearingComponent/ArchiveJson?tags=${this.tags.join('|')}&subjects=${this.subjects.join('|')}&page=${this.page}&hearingStates=${this.hearingStates.join('|')}&dates=${this.dates.join('|')}`;

    axios.get(url).then(response => {
      this._data = response.data;
      if (this.data) {
        const subjectTags = this.data.Tags.Nodes.filter(x => x.RootAlias === 'Hoeringsemner');
        const hearingTypes = this.data.Tags.Nodes.filter(x => x.RootAlias === 'Hoeringstyper');
        const states = this.data.Tags.Nodes.filter(x => x.RootAlias === 'state');
        const dateTags = this.data.Tags.Nodes.filter(x => x.RootAlias === 'dates');
        this.subjectPicker.Update(subjectTags, this.subjects, this.subjectsChangedHandler);
        this.typePicker.Update(hearingTypes, this.tags, this.typesChangedHandler);
        this.statePicker.Update(states, this.hearingStates, this.statesChangedHandler);
        this.datePicker.Update(dateTags, this.dates, this.datesChangeHandler);
      }
    });
  }

  manageParams() {
    this.params = getParamsObj();
    if (this.params.tags) {
      this.tags = this.params.tags.split('|');      
      this.tags = this.tags.filter(x => x != '');
    }
    if(this.params.subjects){
      this.subjects = this.params.subjects.split('|');
      this.subjects = this.subjects.filter(x => x != '');
    }
    if(this.params.hearingStates){
      this.hearingStates = this.params.hearingStates.split('|');
      this.hearingStates = this.hearingStates.filter(x => x != '');
    }

    if (this.params.page) {
      this.page = parseInt(this.params.page);
    }
    if (this.params.dates) {
      this.dates = this.params.dates.split('|');
    }
  }

  managePagination() {
    if (this.numberOfPages == 1) {
      if (this.el.querySelector('.pagination'))
        this.el.querySelector('.pagination').classList.add('hidden');
      return;
    }
    this.el.querySelector('.pagination').classList.remove('hidden');
    const callback = e => {
      const value = e.target.closest('a[data-value]').getAttribute('data-value');
      if (value) {
        if (value == 'prev') this.page--;
        else if (value == 'next') this.page++;
        else this.page = parseInt(value)+1;
        paginationBuilder(this.el.querySelector('.pagination'), this.page, this.numberOfPages, callback);
      }
      updateBrowserState(this.nextUrl());
      this.manageState();
      e.preventDefault();
    };

    paginationBuilder(this.el.querySelector('.pagination'), this.page, this.numberOfPages, callback);
  }

  nextUrl(resetPage = false) {
    if (resetPage) {
      this.page = 1;
    }
    let nextUrl = `?tags=${this.tags.join('|')}&subjects=${this.subjects.join('|')}&page=${this.page}&hearingStates=${this.hearingStates.join('|')}`;
    if (this.dates) {
      nextUrl += `&dates=${this.dates.join('|')}`;
    }
    return nextUrl;
  }
}

export function getParamsObj() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const paramObj = {};
  for (const value of params.keys()) {
    paramObj[value] = params.get(value);
  }
  return paramObj;
}

export function updateBrowserState(paramString) {
  const nextState = { additionalInformation: 'Updated the URL with JS' };
  const params = getParamsObj();
  if (params.sc_lang) {
    paramString = `${paramString}&sc_lang=${params.sc_lang}`;
  }
  const rootUrl = window.location.href.split('?')[0];
  const nextUrl = `${rootUrl}${paramString}`;

  // This will create a new entry in the browser's history, without reloading
  window.history.replaceState(nextState, '', nextUrl);
}

export function setSelectOptions(element, values, value, prop) {
  //popuplate fields if it's empty or only have a non-value option
  const options = element.querySelectorAll('option:not([value=""])');
  if (options.length == 0) {
    values.forEach(v => {
      const o = document.createElement('option');
      if (!prop)
        o.innerText = v.Headline;
      else o.innerText = v[prop];
      o.value = v.Id;
      element.appendChild(o);
    });
  }
  //set selected from state
  if (value) {
    if (Array.isArray(value)) {
      value.forEach(v => {
        if (element.querySelector(`option[value="${v}"]`))
          element.querySelector(`option[value="${v}"]`).selected = true;
      });
    }
    else element.querySelector(`option[value="${value}"]`).selected = true;
  }

}
import axios from 'axios';
import paginationBuilder from '../../util/paginationBuilder';
import { getParamsObj, updateBrowserState } from '../../util/browserUtil';

export default class Search {
  constructor(element) {
    this.el = element;
    this.page = 0;
    this.data = {};
    this.publicationItemHtml = null;
    this.publicationItems = null;
    this.generateCard = this.generateCard.bind(this);
    this.doSearch = this.doSearch.bind(this);
    this.container = this.el.querySelector('.no-results__text');
    this.noResults = this.el.querySelector('.no-result').cloneNode(true);
    this.seeMoreLabel = this.el.getAttribute('data-dictionary');
    this.pagination = document.querySelector('#searchPagination');
    this.page = 1;
    this.numberOfPages = 100;
    this.init();
  }

  init() {
    document.doSearch = this.doSearch;
    document.searchElement = this.el;

    if (getParamsObj()['page']) {
      this.page = getParamsObj()['page'];
      document.searchOverridePage = this.page;
    }

    this.publicationItems = this.el.querySelector('.publicationItems');
  }
  managePagination(input, pagination, page, numberOfPages) {
    if (this.numberOfPages == 1) {
      if (this.el.querySelector('.pagination'))
        this.el.querySelector('.pagination').classList.add('hidden');
      return;
    }
    this.el.querySelector('.pagination').classList.remove('hidden');
    const callback = e => {
      const value = e.target.getAttribute('data-value');
      if (value) {
        if (value == 'prev') page--;
        else if (value == 'next') page++;
        else page = parseInt(value)+1;
        paginationBuilder(pagination, page, numberOfPages, callback);
      }
      this.doSearch(input, page);
      e.preventDefault();
    };

    paginationBuilder(pagination, page, numberOfPages, callback);
  }

  doSearch(input, page) {
    if (!page) page = 1;
    this.page = page;

    if (document.searchOverridePage) {
      this.page = document.searchOverridePage;
      document.searchOverridePage = null;
    }

    const url = `${window.location.origin}/api/searchapi/search`;

    axios
      .post(url, {
        query: input,
        page: this.page-1,
      })
      .then((response) => {
        if (response.data.Results.length == 0) {
          this.noResults.classList.remove('hidden');
        }
        else {
          this.noResults.classList.add('hidden');
        }

        this.generateCard(response);
        this.el.append(this.noResults.cloneNode(true));
        this.numberOfPages = response.data.NumberOfPages;
        this.managePagination(input, this.pagination, this.page, this.numberOfPages);
        updateBrowserState(`?page=${this.page}&term=${getParamsObj()['term']}`);
      });
  }

  generateCard(json) {
    console.log(json.data.Results);
    const replace = (value) => {
      return value.replaceAll('|', ' / ');
    };

    const card = json.data.Results.map(item =>{
      var linkTarget = item.ContentType === 'Page' ? '' : 'target="blank"';
      return `
        <div class="search-results__item flex flex-col flex-nowrap bg-white p-5 px-7 mb-4 drop-shadow-md"> 
            <div class="text-gray-500 mb-2 text-[12px]">
                ${replace(item.Breadcrumb)}
            </div>

            <div class="item__title text-[18px] font-bold text-black mb-2">${item.Title}</div>

            <div class="flex justify-between items-center">
            
                <div class="search__results-item__desc text-gray-600 flex-wrap text-base bg-slate-600 w-3/3">
                  ${item.Description ? `<p class="text-[16px]">${item.Description}</p>` : ''}
                </div>
            
              <div class="text-right">
                <a href="${item.Link}" ${linkTarget} class="self-end inline-flex items-center px-4 py-2 m-2 border-2 border-blue-dark bg-blue-dark text-white rounded-md text-sm hover:bg-blue-dark hover:text-white whitespace-nowrap" href="#">
                  ${this.seeMoreLabel}
                </a>
              </div>
            </div>
        </div>
      `;
    }).join('');

    this.el.innerHTML = card;
  }
}
import Splide from '@splidejs/splide';

export default class Slider {
  constructor(element) {
    this.el = element;
    this.init();
  }

  init() {
    const options = {
      type  : 'loop',
      perPage: 1,
      gap: 0,
      padding: 0,
      rewind: true,
      cover: true,
      width : '100vw',
      snap: true,
      breakpoints: {

      },
    };
    new Splide(this.el, options).mount();
  }
}

import axios from 'axios';
import paginationBuilder from '../../util/paginationBuilder';
import { format } from 'date-fns';
import { getParamsObj, updateBrowserState } from '../../util/browserUtil';

export default class MeetingMinutes {
  constructor(element) {
    this.el = element;
    this.datesPicker = null;
    this.contextId = this.el.getAttribute('data-contextItem');
    this.page = 1;
    this.params = null;
    this.page = 1;
    this.numberOfPages = 100;
    this.data = {};
    this.meetingMinutesItemHtml = null;
    this.meetingMinutesItems = null;
    this.dates = [];
    this.init();
  }
  // manage changes to this.data (change filter states, meetingMinutes etc.)
  set _data(value) {
    this.data = value;
    if (value?.tags) {
      const dateTags = this.data.Tags.Nodes.filter(x => x.RootAlias === 'dates');
      this.datePicker.Update(dateTags, this.dates, this.datesChangeHandler);
    }
    this.numberOfPages = value.Paging.PagesToShow.length;
    this.loadmeetingMinutes(value.Items);
    this.managePagination();
  }
  init() {
    // Element getters
    this.datePicker = this.el.querySelector("#filterDates");

    this.datesChangeHandler = (selectedDates) => {
      this.dates = selectedDates;
      updateBrowserState(this.nextUrl(true));
      this.manageState();
    }

    // load reference html and remove from dom
    this.meetingMinutesItemHtml = this.el.querySelector('#m-minutesItemPlaceholder');
    this.meetingMinutesItemHtml.parentElement.removeChild(this.meetingMinutesItemHtml);
    this.meetingMinutesItemHtml.removeAttribute('id');

    this.meetingMinutesItems = this.el.querySelector('.m-minutesItems');

    this.manageState();
  }

  loadmeetingMinutes(items) {
    this.meetingMinutesItems.innerHTML = '';

    items.forEach(item => {

      const meetingMinutesItem = this.meetingMinutesItemHtml.cloneNode(true);
      meetingMinutesItem.querySelector('.m-minutesItem__headline').innerText = item.Headline;
      meetingMinutesItem.querySelector('.m-minutesItem__url').href = item.Url;

      if (item.ImageUrls?.Items.length) {
        const img = item.ImageUrls.Items.find(x => x.Alias == 'Tile');
        meetingMinutesItem.querySelector('.m-minutesItem__image').src = img.Url;
      }
      else {
        meetingMinutesItem.querySelector('.m-minutesItem__image').remove();
      }
      meetingMinutesItem.querySelector('.m-minutesItem__date').innerText = format(Date.parse(item.MeetingDate), 'dd.MM.yy');
      this.meetingMinutesItems.appendChild(meetingMinutesItem);
    });
  }

  manageState() {
    this.manageParams();
    let url = `${window.location.origin}/api/MeetingMinutes/MeetingMinutesArchiveJson?contextItem=${this.contextId}&page=${this.page}&dates=${this.dates.join('|')}`;
    axios.get(url).then(response => {
      this._data = response.data;
      if(this.data) {
        const dates = this.data.Tags.Nodes.filter(x => x.RootAlias === 'dates');
        this.datePicker.Update(dates, this.dates, this.datesChangeHandler);
      }
    });
  }

  manageParams() {
    this.params = getParamsObj();
    if (this.params.page) {
      this.page = parseInt(this.params.page);
    }
    if (this.params.dates) {
      this.dates = this.params.dates.split('|');
    }
  }

  managePagination() {
    if (this.numberOfPages == 1) {
      if (this.el.querySelector('.pagination'))
        this.el.querySelector('.pagination').classList.add('hidden');
      return;
    }
    this.el.querySelector('.pagination').classList.remove('hidden');
    const callback = e => {
      const value = e.target.closest('a[data-value]').getAttribute('data-value');
      if (value) {
        if (value == 'prev') this.page--;
        else if (value == 'next') this.page++;
        else this.page = parseInt(value)+1;
        paginationBuilder(this.el.querySelector('.pagination'), this.page, this.numberOfPages, callback);
      }
      updateBrowserState(this.nextUrl());
      this.manageState();
      e.preventDefault();
    };

    paginationBuilder(this.el.querySelector('.pagination'), this.page, this.numberOfPages, callback);
  }

  nextUrl(resetPage = false) {
    if (resetPage) {
      this.page = 1;
    }
    let nextUrl =  `?contextItem=${this.contextId}&page=${this.page}`;
    if (this.dates) {
      nextUrl += `&dates=${this.dates.join('|')}`;
    }
    return nextUrl;
  }
}
